<template>
  <div id="main">
    <div class="container-scroller">
      <!-- partial:partials/_navbar.html -->
      <nav
        class="navbar navbar-taqui col-lg-12 col-12 p-0 fixed-top d-flex flex-row"
      >
        <div
          class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center"
        >
          <router-link
            to="/home"
            class="navbar-brand brand-logo mr-5"
            alt="logo"
          >
            <img src="assets/images/logo.png" class="mr-2" alt="logo" />
          </router-link>
          <router-link
            to="/home"
            class="navbar-brand brand-logo-mini"
            alt="logo"
          >
            <img src="assets/images/logo-mini.png" alt="logo" />
          </router-link>
        </div>
        <div
          class="navbar-menu-wrapper d-flex align-items-center justify-content-end"
        >
          <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            <span class="icon-menu"></span>
          </button>

          <ul class="navbar-nav navbar-nav-right">
            <li class="nav-item nav-profile dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <img src="assets/images/user.png" alt="profile" />
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <router-link to="/ajustes" class="dropdown-item">
                  <i class="ti-settings text-primary"></i>
                  Ajustes
                </router-link>
                <a v-on:click="set_logout" class="dropdown-item">
                  <i class="ti-power-off text-primary"></i>
                  Cerrar sesión
                </a>
              </div>
            </li>
          </ul>
          <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span class="icon-menu"></span>
          </button>
        </div>
      </nav>
      <!-- partial -->
      <div class="container-fluid page-body-wrapper">
        <!-- partial:partials/_sidebar.html -->
        <nav class="sidebar sidebar-offcanvas" id="sidebar">
          <ul class="nav">
            <li class="nav-item">
              <router-link class="nav-link" to="/home">
                <i class="fas fa-home mr-2"></i>
                <span class="menu-title">Inicio</span>
              </router-link>
            </li>
            <li v-if="perfil == 1" class="nav-item">
              <router-link class="nav-link" to="/usuarios">
                <i class="fas fa-users mr-2"></i>
                <span class="menu-title">Usuarios</span>
              </router-link>
            </li>

            <li v-if="perfil == 1" class="nav-item">
              <router-link class="nav-link" to="/eventos">
                <i class="fas fa-calendar-day mr-2"></i>
                <span class="menu-title">Eventos</span>
              </router-link>
            </li>
          </ul>
        </nav>
        <!-- partial -->
        <div class="main-panel">
          <div class="content-wrapper">
            <router-view :perfil="perfil" />
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->
          <footer class="footer"></footer>
          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "Main",
  data() {
    return {
      perfil: null,
    };
  },
  created: function () {
    var session = localStorage.getItem("sess_taqui_web");
    session = JSON.parse(session);
    this.perfil = session.usuario.perfil;
  },
  methods: {
    set_logout: function () {
      localStorage.removeItem("sess_taqui_web");
      location.reload();
    },
  },
  mounted: function () {
    var body = $("body");
    $('[data-toggle="minimize"]').on("click", function () {
      if (
        body.hasClass("sidebar-toggle-display") ||
        body.hasClass("sidebar-absolute")
      ) {
        body.toggleClass("sidebar-hidden");
      } else {
        body.toggleClass("sidebar-icon-only");
      }
    });
    $('[data-toggle="offcanvas"]').on("click", function () {
      $(".sidebar-offcanvas").toggleClass("active");
    });
  },
};
</script>
